<template>
    <div style="position:relative" v-bind:class="{'open':openSuggestion}">

        <div class="vs-component vs-con-input-label vs-input w-full vs-input-primary">
            <label class="vs-input--label" v-if="label">{{label}}</label>
            <input type="text" class="vs-inputx vs-input--input normal hasValue"
                   :value="value"
                   @input="$emit('input', $event.target.value)"
                   @focus="show()"
                   v-on:blur="remove()"
                   @keydown.enter = 'enter'
                   @keydown.down = 'down'
                   @keydown.up = 'up'
            >
        </div>


        <div class="overlay-autocomplete" v-if="overlayAutocomplete && matches.length >= 1">
            <ul class="dropdown-menu " style="width:100%">
                <li v-for="(suggestion,index) in matches"  v-bind:class="{'active': isActive(index)}">
                    <span  @click="suggestionClick(index)">{{ suggestion }} </span>
                </li>
            </ul>
        </div>

    </div>
</template>

<script>
    export default {
        name: "autocomplete",

        data() {
            return {
                overlayAutocomplete: false,
                open: false,
                current: -1,
                content: this.value
            }
        },
        props: {
            suggestions: {
                type: Array,
                required: true
            },
            label: {
                type: String,
            },
            value: {
                type: String,
            },
            clickdata: {
                type: String,
            },
        },
        computed: {
            matches() {
                return this.suggestions.filter((str) => {
                    return str.indexOf(this.value) >= 0;
                });
            },
            openSuggestion() {
                return this.value !== "" &&
                    this.matches.length != 0 &&
                    this.open === true;
            }
        },
        methods: {
            show() {
                this.overlayAutocomplete = true
            },
            remove() {
                setTimeout(()=>{
                    this.overlayAutocomplete = false
                }, 200);
            },
            enter() {
                this.$emit('update:clickdata', this.matches[this.current])
                this.content = this.matches[this.current];
                this.open = false;
            },
            up() {
                if(this.current > 0)
                    this.current--;
            },
            down() {
                if(this.current < this.matches.length - 1)
                    this.current++;
            },
            isActive(index) {
                return index === this.current;
            },
            change() {

                if (this.open == false) {
                    this.open = true;
                    // this.current = 0;
                }
            },
            suggestionClick(index) {
                this.$emit('update:clickdata', this.matches[index])
                this.content = this.matches[index];
                this.current = index;
                this.open = false;
            },
        }
    }
</script>

<style scoped>
    .overlay-autocomplete {
        position: absolute;
        background: white;
        width: 100%;
        width: 100%;
        background: #fff;
        border-radius: .5rem;
        /* display: block; */
        -webkit-box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
        /* position: relative; */
        -webkit-transition: all .3s ease-in-out;
        transition: all .3s ease-in-out;
        padding: 8px;
        max-height: 120px;
        overflow-y: scroll;
        z-index: 5000;
    }

    .overlay-autocomplete ul li span {
        color: black;
    }
    .overlay-autocomplete ul li span:hover {
        color: #e24733;
        cursor: pointer;
    }
    .overlay-autocomplete ul li.active span {
        color: #e24733!important;

    }
</style>