<template>
  <vs-popup id="custom-price--modal" :title="title" :active="visible" @close="onClickClose" class="custom-price-modal">
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <mg-select :options="products"
                   track-by="id"
                   label="name"
                   placeholder="Produkt auswählen"
                   v-model="customPriceData.article_id"
                   @select="onSelectProduct" >
        </mg-select>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input v-model="customPriceData.price" label="Preis" class="w-full" v-currency></vs-input>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button @click="onClickSave" class="mr-3 mb-2">Speichern</vs-button>
        <vs-button color="warning" type="border" class="mb-2">Abbrechnen</vs-button>
      </div>
    </div>
  </vs-popup>
</template>

<script>
import MgSelect from "@/components/mg-select/MgSelect";
import ApiService from "@/api";
import PriceHelper from "@/mixins/helper/price.helper";
export default {
  name: "ClientCustomPriceModal",
  components: {MgSelect},
  props: {
    active: {
      type: Boolean,
      default: true
    },
    clientId: {
      type: Number
    },
    customPrice: {
      type: Object
    }
  },

  data(){
    return {
      title: 'Kunden Individuelle Preis',
      customPriceData: {
        article_id: null,
        price: 0,
        client_id: null
      },
      products: []
    }
  },
  computed: {
    visible() {
      return this.active;
    },
  },
  methods: {
    onClickClose() {
      this.$emit('update:active', false)
    },
    onSelectProduct(value){
      this.customPriceData.price = value.price
    },
    fetchProducts(){
      ApiService.get('articles').then(response => {

        if (response.status !== 200) {
          return;
        }

        this.products = response.data.result;
      });
    },
    onClickSave(){

      let postData = this.customPriceData;
      postData.client_id = this.clientId;
      postData.price = PriceHelper.parseCommaFloat(postData.price)
      if (this.customPrice !== null){
        ApiService.put('article_custom_prices/' + postData.id, postData).then(response => {
          this.$emit('change')
          this.onClickClose()
        });
        return
      }
      ApiService.post('article_custom_prices', postData).then(response => {

        this.$emit('change')
        this.onClickClose()
      });
    }
  },

  watch: {
    active(value) {
      if (value) {
        this.fetchProducts();
        if (this.customPrice !== null){
          let data = JSON.parse(JSON.stringify(this.customPrice))
          this.customPriceData.article_id = data.article_id;
          this.customPriceData.price = data.price;
          this.customPriceData.id = data.id;
        } else{
          this.customPriceData = {
            article_id: null,
            price: 0,
            client_id: null
          }
        }
      }
    },
  }
}
</script>

<style scoped>

</style>