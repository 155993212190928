<template>
  <div class="client-contracts" v-if="customPricesData">
    <div>
      <vs-button class="mb-2 float-right" @click="onClickAddCustomPrice">Hinzufügen</vs-button>
    </div>
    <vs-table :sst="true" @sort="handleSort" max-items="10" pagination :data="customPricesData">

      <template slot="thead" >
        <vs-th sort-key="article_id">Artikel</vs-th>
        <vs-th sort-key="price">Preise</vs-th>
        <vs-th>Actions</vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
          <vs-td :data="data[indextr].article.name">
            {{ data[indextr].article.name }}
          </vs-td>
          <vs-td :data="data[indextr].price">
            {{ parseCurrency(data[indextr].price) }}
          </vs-td>

          <vs-td>
            <div class="column-actions-group flex">
              <vx-tooltip text="Bearbeiten" position="left">
                <vs-button class="mr-1" color="warning" type="filled" icon="edit" size="small"
                           @click="onClickEditCustomPrice(data[indextr])"
                ></vs-button>

              </vx-tooltip>
              <vx-tooltip text="Löschen" position="left">
                <vs-button color="danger" type="filled" icon="remove_circle_outline" size="small"
                           @click="onClickRemoveCustomPrice(data[indextr].id)"
                ></vs-button>
              </vx-tooltip>
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <client-custom-price-modal @change="fetchCustomPrices" :active.sync="modalActive" :client-id="clientId" :custom-price="currentCustomPrice"></client-custom-price-modal>
  </div>
</template>
<script>
import ApiService from "../../../../api";
import ClientCustomPriceModal from "@/views/organization/clients/module/ClientCustomPriceModal";
import PriceHelper from "@/mixins/helper/price.helper";

export default {
  name: 'ClientCustomPrices',
  components: {ClientCustomPriceModal},
  props:['clientId'],

  data(){
    return {
      customPricesData: null,
      modalActive: false,
      currentCustomPrice: null,
    }
  },
  mounted() {
    this.fetchCustomPrices()
  },

  methods: {
    fetchCustomPrices(){
      ApiService.get('article_custom_prices?filter[0][field]=client_id&filter[0][expression]=exact&filter[0][value]=' + this.clientId)
          .then((response) => {
            if (response.data.status === 'success') {
              this.customPricesData = response.data.result.result;
            }
          })
    },
    handleSort(){

    },
    onClickAddCustomPrice(){
      this.currentCustomPrice = null
      this.modalActive = true
    },
    onClickEditCustomPrice(customPrice){
      this.currentCustomPrice = customPrice
      this.modalActive = true
    },
    onClickRemoveCustomPrice(id){
      ApiService.delete('article_custom_prices/' + id)
          .then((response) => {
            if (response.data.status === 'success') {
              this.fetchCustomPrices()
            }
          })
    },
    parseCurrency(value){
      return PriceHelper.formatCurrency(value)
    }

  },
}
</script>