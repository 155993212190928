<template>
    <div class="client-invoices" v-if="data ">
        <vs-table  @sort="handleSort" max-items="10" pagination :data="data" stripe >
            <template slot="thead" >
                <vs-th sort-key="status">Menge</vs-th>
                <vs-th sort-key="invoice_number">Einzelpreis</vs-th>
                <vs-th sort-key="order_date">Beschreibung</vs-th>
                <vs-th sort-key="sum">Gesamtpreis</vs-th>
                <vs-th>Actions</vs-th>
            </template>

            <template slot-scope="{data}">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data" >

                    <vs-td :data="data[indextr].volume_unit">
                      {{ data[indextr].amount }}
                      {{getVolumeUnitNameById(data[indextr].volume_unit)}}

                    </vs-td>

                    <vs-td :data="data[indextr].unit_price">
                        {{ data[indextr].unit_price }}
                    </vs-td>

                    <vs-td :data="data[indextr].article_name">
                        {{ data[indextr].article_name }}
                    </vs-td>

                    <vs-td>
                        {{ parseCurrency(data[indextr].amount*data[indextr].unit_price) }}
                    </vs-td>

                    <vs-td>
                        <div class="column-actions-group flex">
                            <vx-tooltip text="Einsehen" position="left">
                                <vs-button class="mr-1" color="dark" type="filled"
                                           icon="edit" size="small"
                                           :to="'/accounting/'+ data[indextr].id"
                                ></vs-button>
                            </vx-tooltip>
                        </div>
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
        Summe: {{parseCurrency(dataSum)}}
    </div>
</template>

<script>
    import ApiService from "../../../../api";
    import PriceHelper from "@/mixins/helper/price.helper";
    import moment from 'moment';
    import * as qs from "qs";
    import QueryHelper from "../../../../mixins/helper/query.helper";
    import staticOptions from "../../../../mixins/static/options";

    export default {
        name: 'ClientAccountings',

        props: ['dataFilter'],

        data(){
            return {
                data: null,
                dataSum: [],

            }
        },
        computed: {


        },
        mounted() {
            this.fetchData()
        },
        methods: {
            fetchData(){
                ApiService.get('accountings', {
                    params: this.dataFilter,
                    paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
                })
                    .then((response) => {
                        if (response.data.status === 'success') {
                            this.fetched = true;
                            this.data = response.data.result;
                            this.calcSum();
                            console.log(this.data[0].volume_unit)
                        }
                    })
            },
            calcSum(){
                if(this.fetched) {
                    let dataSum = null;
                    for(let i = 0;i < this.data.length; i++) {
                            dataSum = dataSum + (this.data[i].unit_price*this.data[i].amount);
                    }
                    this.dataSum = dataSum
                } else {
                    this.dataSum = null;
                }
            },
            handleSort(){

            },
            parseCurrency(value){
                return PriceHelper.formatCurrency(value)
            },
            formatDate(value) {
                return moment(new Date(value)).format('DD.MM.YYYY')
            },
            getVolumeUnitNameById(id) {
                const units = staticOptions.units.find(item => item.value === id);
                return units.labelShort
            },
        }
    }
</script>