<template>
    <vx-card>
      <vs-row>
        <vs-col vs-type="flex" vs-align="center" vs-w="8">
            <h4 class="md-4">Interne Ansprechpartner</h4>
        </vs-col>
        <vs-col vs-type="flex" class="justify-end" vs-align="center" vs-w="4">
          <vs-button color="warning" type="border" class="mb-4" @click="addEmptyEntry">
            Kollegen zuweisen
          </vs-button>
        </vs-col>
      </vs-row>

      <div class="vx-row">
        <div class="vx-col lg:w-full w-full">
          <div class="vx-col">

            <vs-row v-for="entry, index in userToClient" :key="index" style="margin-bottom: 10px">
              <vs-col vs-type="flex" vs-align="center" vs-w="4">
<!--                <mg-select v-model="entry.user_id" track-by="user_id" label="name" :options="users" placeholder="Name:"></mg-select>-->
                <vs-select v-model="entry.user_id"   :disabled="entry.id ">
                  <vs-select-item v-for="(user, index) in users"
                                  :key="index"
                                  :value="user.id"
                                  :text="user.detail.first_name + ' ' + user.detail.last_name" />
                </vs-select>

              </vs-col>

              <vs-col vs-type="flex" vs-align="center" vs-w="7">
                <vs-input class="w-full" data-vv-as="description"
                          type="text"
                          v-model="entry.description"
                          placeholder="Funktion:"
                          v-validate="'required'"/>
              </vs-col>

              <vs-col vs-type="flex" vs-align="center" vs-w="1">
                <div class="deleteIcon" v-if="entry.id">
                  <vx-tooltip text="Löschen" position="left">
                    <vs-button color="primary"
                               type="filled"
                               icon="delete_forever"
                               size="small"
                               @click="openDeleteConfirm(entry.id)"></vs-button>
                  </vx-tooltip>
                </div>
              </vs-col>
            </vs-row>
          </div>
        </div>
      </div>
    </vx-card>

</template>
<script>
import MgSelect from "@/components/mg-select/MgSelect";
import ApiService from "../../../../api";

export default {
  name: 'UserToClient',

  components: {
    MgSelect
  },

  props:[
      'userToClient',
      'client_id'
  ],

  data() {
    return {
      users: [],
      newEntry: {
        user_id: '',
        client_id: this.client_id,
        description: ''
      }
    }
  },

  mounted() {
    this.fetchUsers()
  },

  computed: {},

  methods: {
    fetchUsers() {
      this.$vs.loading()
      ApiService.get('users').then(response => {
        if (response.data.status === 'success') {
          this.users = response.data.result;
          this.$vs.loading.close()
        } else {
          this.$vs.notify({
            title: 'Fehler',
            text: 'Es ist ein Fehler aufgetreten',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'error'
          })
        }
      }).catch(response => {

        this.$vs.notify({
          title: 'Fehler',
          text: 'Es ist ein Fehler aufgetreten',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'error'
        })
        this.$vs.loading.close()
      })
    },

    addEmptyEntry() {
      this.userToClient.push(JSON.parse(JSON.stringify(this.newEntry)))
    },

    openDeleteConfirm(id) {
      this.selectedForDialog = id;

      this.$vs.dialog({
        type: 'confirm',
        color: 'primary',
        title: 'Bestätigen',
        text: 'Sind Sie sich sicher dass diese Zuordnung gelöscht werden soll?',
        accept: this.acceptDelete,
        acceptText: 'Löschen',
        cancelText: 'Abbrechen'
      })
    },
    acceptDelete() {
      ApiService.delete('clients/userToClient/' + this.selectedForDialog).then((response) => {

        this.$emit('fetchClientData')

        if (response.data.status === 'success') {
          return this.$vs.notify({
            title: 'Erfolgreich',
            text: 'Interner Ansprechpartner wurde erfolgreich gelöscht',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          });
        }

        this.$vs.notify({
          title: 'Fehlgeschlagen',
          text: 'Interner Ansprechpartner konnte nicht gelöscht werden.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      }).catch((error) => {
        this.$vs.notify({
          title: 'Fehlgeschlagen',
          text: 'Interner Ansprechpartner konnte nicht gelöscht werden.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
    },
  }
}

</script>
<style>

</style>