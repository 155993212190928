<template>
    <div class="client-details" v-if="fetched && clientData && contactData">
        <div class="vx-row mb-base">
            <div class="vx-col lg:w-1/2 w-full">
                <vx-card class="h-full">
                    <vs-row>
                        <vs-col vs-type="flex" vs-align="center" vs-w="9">
                            <h4 class="mb-4">Rechnungsanschrift</h4><br>
                        </vs-col>
                        <vs-col vs-type="flex" vs-align="center" vs-w="3">
                            <p class="created-on pb-3">Erstellt: {{ formatDate(clientData.created_at) }}</p>
                        </vs-col>
                    </vs-row>
                    <div class="vx-row">
                        <div class="vx-col lg:w-full w-full">
                            <vs-col vs-type="flex" class="rx-row mt-4 pb-3">
                                <vs-col class="vx-col lg:w-1/3">
                                    <vs-col>
                                        <vs-col vs-type="flex" vs-align="center" vs-w="7">
                                            <vs-switch color="success" v-model="clientData.active" icon-pack="feather"
                                                       vs-icon="icon-check"/>
                                            <label class="switch-label">Status</label>
                                        </vs-col>
                                        <vs-col vs-type="flex" vs-align="center" vs-w="7" class="pt-2">
                                            <vs-switch color="success" v-model="clientData.fast_registration"
                                                       icon-pack="feather" vs-icon="icon-check"/>
                                            <label class="switch-label">Schnellkunde</label>
                                        </vs-col>
                                    </vs-col>
                                </vs-col>
                                <div class="vx-col lg:w-1/3">
                                    <vs-col vs-type="flex" vs-align="center" vs-w="7">
                                        <vs-switch color="success" icon-pack="feather"
                                                   v-model="clientData.business_client" vs-icon="icon-check"/>
                                        <label class="switch-label">Geschäftskunde</label>
                                    </vs-col>
                                </div>
                                <div class="vx-col lg:w-1/3">
                                    <p>Typ:
                                        <mg-select :options="typeOptions" v-model="clientData.type"
                                                   placeholder="Type auswählen" track-by="value" label="label"
                                                   @select="selectType">
                                        </mg-select>


                                    </p>
                                    <p>Categorie: XX</p>
                                </div>
                            </vs-col>
                            <vs-divider/>
                        </div>
                        <div class="vx-col lg:w-full w-full">
                            <div class="vx-row">
                                <div class="vx-col lg:w-full">
                                    <strong>Kundennummer:</strong> {{ clientData.customer_number }}
                                </div>
                            </div>
                            <div class="vx-row mt-4">
                                <div class="vx-col lg:w-full">
                                    <vs-input class="w-full" label="Firma *" data-vv-as="Firma" name="company_name"
                                              v-model="clientData.company_name" type="text" v-validate="'required'"/>
                                    <span class="text-danger text-sm" v-if="errors.has('company_name')">{{
                                        errors.first('company_name')
                                        }}</span>
                                </div>
                            </div>
                            <div class="vx-row mt-4">
                                <div class="vx-col lg:w-1/2">
                                    <vs-input class="w-full" label="Anschrift 1 *" data-vv-as="Anschrift 1"
                                              name="address_line_1" v-model="clientData.address_line_1" type="text"
                                              v-validate="'required'"/>
                                    <span class="text-danger text-sm"
                                          v-if="errors.has('address_line_1')">{{
                                        errors.first('address_line_1')
                                        }}</span>
                                </div>
                                <div class="vx-col lg:w-1/2">
                                    <vs-input class="w-full" label="Anschrift 2 *" data-vv-as="Anschrift 2"
                                              name="address_line_2" v-model="clientData.address_line_2" type="text"
                                              v-validate="'required'"/>
                                    <span class="text-danger text-sm"
                                          v-if="errors.has('address_line_2')">{{
                                        errors.first('address_line_2')
                                        }}</span>
                                </div>
                            </div>
                            <div class="vx-row mt-4">
                                <div class="vx-col lg:w-1/3">
                                    <vs-input class="w-full" label="Postleitzahl *" data-vv-as="Postleitzahl"
                                              name="zip_code" v-model="clientData.zip_code" type="text"
                                              v-validate="'required'"/>
                                    <span class="text-danger text-sm"
                                          v-if="errors.has('zip_code')">{{ errors.first('zip_code') }}</span>
                                </div>

                                <div class="vx-col lg:w-2/3">
                                    <vs-input class="w-full" label="Standort *" data-vv-as="Standort" name="city"
                                              v-model="clientData.city" type="text" v-validate="'required'"/>
                                    <span class="text-danger text-sm" v-if="errors.has('city')">{{
                                        errors.first('city')
                                        }}</span>
                                </div>
                            </div>
                            <div class="vx-row mt-4">
                                <div class="vx-col lg:w-1/3">
                                    <vs-select label="Land" class="w-full" v-model="clientData.country">
                                        <vs-select-item :key="index" :value="item.value" :text="item.label"
                                                        v-for="item, index in countries"/>
                                    </vs-select>
                                </div>
                            </div>
                            <div class="vx-row mt-4">
                                <div class="vx-col lg:w-1/2">
                                    <vs-input class="w-full" label="Telefon" data-vv-as="Telefon" name="phone"
                                              v-model="clientData.phone" type="text"/>
                                </div>
                                <div class="vx-col lg:w-1/2">
                                    <vs-input class="w-full" label="Fax" data-vv-as="Fax" name="fax"
                                              v-model="clientData.fax" type="text"/>
                                </div>
                            </div>
                            <div class="vx-row mt-4">
                                <div class="vx-col lg:w-full">
                                    <vs-input class="w-full" label="E-Mail-Adresse *" data-vv-as="E-Mail-Adresse"
                                              name="email" v-model="clientData.email" type="email"
                                              v-validate="'required'"/>
                                    <span class="text-danger text-sm"
                                          v-if="errors.has('email')">{{ errors.first('email') }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </vx-card>
            </div>

            <div class="vx-col lg:w-1/2 w-full">

                <user-to-client :userToClient="clientData.user_to_clients" :client_id="clientData.id"
                                @fetchClientData="fetchClientData()"></user-to-client>

                <vx-card title="Weitere Details" class="mt-4">
                    <div class="vx-row">
                        <div class="vx-col lg:w-full w-full">
                            <div class="vx-row">
                                <div class="vx-col lg:w-full">
                                    <vs-input class="w-full" label="Webseite" data-vv-as="Webseite" name="website"
                                              v-model="clientData.websites" type="text" v-validate="'required'"/>
                                </div>
                            </div>
                            <div class="vx-row mt-4">
                                <div class="vx-col lg:w-1/3">
                                    <vs-input class="w-full" label="Ust. Id Nr" data-vv-as="Ust. Id Nr"
                                              name="ust_id" v-model="clientData.ust_id" type="text"
                                              v-validate="'required'"/>
                                </div>
                                <div class="vx-col lg:w-1/3">
                                    <vs-input class="w-full" label="Payment Deadline in Tage"
                                              data-vv-as="Payment Deadline in Tage"
                                              name="payment_deadline" v-model="clientData.payment_deadline"
                                              type="text"/>
                                </div>
                                <div class="vx-col lg:w-1/3">
                                    <vs-input class="w-full" label="Min. Rechnungswert bei Abrechnung"
                                              data-vv-as="Min. Rechnungswert bei Abrechnung"
                                              name="payment_deadline" v-model="clientData.min_cost__every_accounting"
                                              type="text"/>
                                </div>
                            </div>
                            <div class="vx-row mt-4">
                                <div class="vx-col lg:w-1/2">
                                    <vs-input class="w-full" label="IBAN" data-vv-as="IBAN" name="bank_iban"
                                              v-model="clientData.bank_iban" type="text"/>
                                </div>
                                <div class="vx-col lg:w-1/2">
                                    <vs-input class="w-full" label="SWIFT/BIC" data-vv-as="SWIFT/BIC" name="bank_bic"
                                              v-model="clientData.bank_bic" type="text"/>
                                </div>
                            </div>
                            <div class="vx-row mt-4">
                                <div class="vx-col lg:w-full">
                                    <vs-input class="w-full" label="Kontoinhaber" data-vv-as="Kontoinhaber"
                                              name="bank_account_owner" v-model="clientData.bank_account_owner"
                                              type="text"/>
                                </div>
                            </div>
                            <div class="vx-row mt-4">
                                <div class="vx-col lg:w-full">
                                    <vs-input class="w-full" label="Zusätzliche Rechnungsempfänger (Bsp.: rechnung@...)"
                                              data-vv-as="additional_invoice_recipient"
                                              name="bank_account_owner"
                                              v-model="clientData.additional_invoice_recipient"
                                              type="text"/>
                                </div>
                            </div>
                            <div class="vx-row mt-4">
                                <div class="vx-col lg:w-1/3 logo-upload">
                                    <strong>Logo (PNG; 300PX Breite/Höhe)</strong>
                                    <div class="flex mt-4">
                                        <label for="logoUpload">
                                            <feather-icon icon="UploadIcon" class="inline-block mr-2"
                                                          svgClasses="w-8 h-8"/>
                                        </label>
                                        <input type="file" id="logoUpload">
                                    </div>
                                </div>
                                <div class="vx-col lg:w-1/3 contract-upload">
                                    <strong>AV-Vertrag</strong><br>
                                    <div class="flex mt-4">
                                        <vs-button color="warning" type="border" class="mr-3 mb-auto" size="small"
                                                   target="_blank"
                                                   :href="`${$globalUrl}storage/client_files/${clientData.dsgvo_file}`"
                                                   v-if="clientData.dsgvo_file">
                                            Aktuellen Vertag einsehen
                                        </vs-button>
                                        <label for="contractUpload">
                                            <feather-icon icon="UploadIcon" class="inline-block mr-2"
                                                          svgClasses="w-8 h-8"/>
                                        </label>
                                        <input type="file" id="contractUpload" @input="uploadDataProcessingContract"
                                               accept="application/pdf">
                                        <vs-button color="warning" type="border" class="mr-3 mb-auto" size="small"
                                                   target="_blank"
                                                   @click="deleteDsgvoFile"
                                                   v-if="clientData.dsgvo_file"
                                        >
                                            Entfernen
                                        </vs-button>
                                    </div>
                                </div>
                                <div class="vx-col lg:w-1/3 nda-upload">
                                    <strong>NDA-Vertrag</strong><br>
                                    <div class="flex mt-4">
                                        <vs-button color="warning" type="border" class="mr-3 mb-auto" size="small"
                                                   target="_blank"
                                                   :href="`${$globalUrl}storage/client_files/${clientData.nda_file}`"
                                                   v-if="clientData.nda_file">
                                            Aktuellen Vertag einsehen
                                        </vs-button>
                                        <label for="NDAcontractUpload">
                                            <feather-icon icon="UploadIcon" class="inline-block mr-2"
                                                          svgClasses="w-8 h-8"/>
                                        </label>
                                        <input type="file" id="NDAcontractUpload"
                                               @input="uploadDataProcessingNDAContract"
                                               accept="application/pdf">
                                        <vs-button color="warning" type="border" class="mr-3 mb-auto" size="small"
                                                   target="_blank"
                                                   @click="deleteNDAFile"
                                                   v-if="clientData.nda_file"
                                        >
                                            Entfernen
                                        </vs-button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </vx-card>
            </div>
            <div class="vx-col lg:w-full w-full pt-6">
                <vx-card>
                    <vs-row>
                        <vs-col vs-type="flex" vs-align="center" vs-w="6">
                            <h4 class="mb-4">Mitarbeiter</h4><br>
                        </vs-col>
                        <vs-col vs-type="flex" vs-align="center" vs-w="6" class="justify-end">
                            <!--   <vs-button color="grey" type="border" class="mb-4">Zuweisen
                               </vs-button>-->
                            <vs-button color="success" type="filled" class="ml-3 mb-4" @click="createEmptyContact()">
                                Mitarbeiter
                                erstellen
                            </vs-button>
                        </vs-col>
                    </vs-row>

                    <template>
                        <div class="client-contracts">
                            <vs-table :sst="true" @sort="handleSort" max-items="10" pagination :data="contactData">
                                <template slot="thead">
                                    <vs-th sort-key="status">Aktiv</vs-th>
                                    <vs-th sort-key="salutation">Ansprache</vs-th>
                                    <vs-th sort-key="first_name">Vorname</vs-th>
                                    <vs-th sort-key="last_name">Nachname</vs-th>
                                    <vs-th sort-key="letter_salutation">Ansprache</vs-th>
                                    <vs-th sort-key="position">Position</vs-th>
                                    <vs-th sort-key="phone">Tel</vs-th>
                                    <vs-th sort-key="phone">Mobil</vs-th>
                                    <vs-th sort-key="email">Email</vs-th>
                                    <vs-th sort-key="email">Interner <br>Kommentar</vs-th>
                                    <vs-th sort-key="price">Vertret.<br>Hauptansp</vs-th>
                                    <vs-th sort-key="price">Rechungs-<br>empfänger</vs-th>
                                    <vs-th sort-key="price">Newsletter</vs-th>
                                    <vs-th>Aktionen</vs-th>
                                </template>

                                <template slot-scope="{data}">
                                    <vs-tr :key="indextr" v-for="(tr, indextr) in data" :class="{
                     'inline-edit':  editContactOffset === indextr
                    }">
                                        <vs-td :data="data[indextr].contact2clients.active">
                                            <div v-if="editContactOffset!=indextr">
                                                <div v-if="data[indextr].contact2clients.active === 1">
                                                    <span class="mega--circle green"></span>
                                                </div>
                                                <div v-else>
                                                    <span class="mega--circle bg-gray-300 "></span>
                                                </div>
                                            </div>
                                            <vs-switch v-show="editContactOffset==indextr"
                                                       v-if="editContactOffset != -1" color="success"
                                                       v-model="editContact.contact2clients.active"/>
                                        </vs-td>

                                        <vs-td :data="data[indextr].salutation">
                                            <div v-if="editContactOffset != indextr">
                                                <span v-if="data[indextr].salutation === 'mr'">Herr</span>
                                                <span v-else-if="data[indextr].salutation === 'ms'">Frau</span>
                                                <span v-else>-</span>
                                            </div>
                                            <vs-select
                                                    class="selectExample w-full"
                                                    name="salutation"
                                                    v-model="data[indextr].salutation"
                                                    v-show="editContactOffset==indextr" v-if="editContactOffset != -1"
                                            >
                                                <vs-select-item :key="index"
                                                                :value="item.value" :text="item.label"
                                                                v-for="item,index in salutationsOptions"/>
                                            </vs-select>


                                        </vs-td>
                                        <vs-td :data="data[indextr].first_name">
                                            <span v-if="editContactOffset != indextr">{{
                                                data[indextr].first_name
                                                }}</span>
                                            <vs-input v-model="editContact.first_name"
                                                      v-show="editContactOffset==indextr"
                                                      v-if="editContactOffset != -1" @keydown.enter="saveContactEditing"
                                                      @keydown.esc="cancelContactEditing"/>
                                        </vs-td>
                                        <vs-td :data="data[indextr].last_name">
                                            <span v-if="editContactOffset != indextr">{{
                                                data[indextr].last_name
                                                }}</span>
                                            <vs-input v-model="editContact.last_name"
                                                      v-show="editContactOffset==indextr"
                                                      v-if="editContactOffset != -1" @keydown.enter="saveContactEditing"
                                                      @keydown.esc="cancelContactEditing"/>
                                        </vs-td>
                                        <vs-td>
                                            <small v-if="editContactOffset != indextr">{{
                                                data[indextr].letter_salutation
                                                }}</small>
                                            <vs-input v-model="editContact.letter_salutation"
                                                      v-show="editContactOffset==indextr"
                                                      v-if="editContactOffset != -1" @keydown.enter="saveContactEditing"
                                                      @keydown.esc="cancelContactEditing"/>
                                        </vs-td>
                                        <vs-td>
                                            <span v-if="editContactOffset != indextr">{{
                                                data[indextr].contact2clients.position
                                                }}</span>
                                            <vs-input v-model="editContact.contact2clients.position"
                                                      v-show="editContactOffset==indextr"
                                                      v-if="editContactOffset != -1" @keydown.enter="saveContactEditing"
                                                      @keydown.esc="cancelContactEditing"/>
                                        </vs-td>
                                        <vs-td>
                                            <span v-if="editContactOffset != indextr">{{ data[indextr].phone }}</span>
                                            <vs-input v-model="editContact.phone" v-show="editContactOffset==indextr"
                                                      v-if="editContactOffset != -1" @keydown.enter="saveContactEditing"
                                                      @keydown.esc="cancelContactEditing"/>
                                        </vs-td>
                                        <vs-td>
                                            <span v-if="editContactOffset != indextr">{{ data[indextr].mobile }}</span>
                                            <vs-input v-model="editContact.mobile" v-show="editContactOffset==indextr"
                                                      v-if="editContactOffset != -1" @keydown.enter="saveContactEditing"
                                                      @keydown.esc="cancelContactEditing"/>
                                        </vs-td>
                                        <vs-td>
                                            <a :href="`mailto:${data[indextr].email}`"
                                               v-if="editContactOffset != indextr">
                                                {{ data[indextr].email }}
                                            </a>
                                            <vs-input v-model="editContact.email" v-show="editContactOffset==indextr"
                                                      v-if="editContactOffset != -1" @keydown.enter="saveContactEditing"
                                                      @keydown.esc="cancelContactEditing"/>
                                        </vs-td>
                                        <vs-td>
                                            <div style="max-width: 150px;max-height: 53px;overflow-x: hidden;overflow-y: auto;"
                                                 :title="data[indextr].internal_comment">
                                                <small v-if="editContactOffset != indextr"><small>{{
                                                    data[indextr].internal_comment
                                                    }}</small></small>
                                            </div>
                                            <vs-textarea v-model="editContact.internal_comment"
                                                         v-show="editContactOffset==indextr"
                                                         v-if="editContactOffset != -1" style="width: 150px;"/>
                                        </vs-td>
                                        <vs-td :data="data[indextr].contact2clients.is_main_contact">
                                            <div v-if="data[indextr].contact2clients.is_main_contact == 1 && editContactOffset!=indextr">
                                                <feather-icon icon="CheckIcon" class="inline-block mr-2 green"
                                                              svgClasses="w-6 h-6"/>
                                            </div>

                                            <vs-switch v-show="editContactOffset==indextr"
                                                       v-if="editContactOffset != -1" color="success"
                                                       v-model="editContact.contact2clients.is_main_contact"/>
                                        </vs-td>
                                        <vs-td :data="data[indextr].contact2clients.is_invoice_recipient">
                                            <div v-if="data[indextr].contact2clients.is_invoice_recipient == 1 && editContactOffset!=indextr">
                                                <feather-icon icon="CheckIcon" class="inline-block mr-2 green"
                                                              svgClasses="w-6 h-6"/>
                                            </div>

                                            <vs-switch v-show="editContactOffset==indextr"
                                                       v-if="editContactOffset != -1" color="success"
                                                       v-model="editContact.contact2clients.is_invoice_recipient"/>
                                        </vs-td>
                                        <vs-td :data="data[indextr].is_newsletter_recipient">
                                            <div v-if="data[indextr].is_newsletter_recipient == 1 && editContactOffset!=indextr">
                                                <feather-icon icon="CheckIcon" class="inline-block mr-2 green"
                                                              svgClasses="w-6 h-6"/>
                                            </div>

                                            <vs-switch v-show="editContactOffset==indextr"
                                                       v-if="editContactOffset != -1" color="success"
                                                       v-model="editContact.is_newsletter_recipient"/>

                                        </vs-td>

                                        <vs-td>
                                            <div class="column-actions-group flex">
                                                <vx-tooltip text="Bearbeiten" position="left"
                                                            v-if="editContactOffset!=indextr">
                                                    <vs-button class="mr-1" color="warning" type="filled" icon="edit"
                                                               size="small"
                                                               @click="startContactEditing(indextr)"
                                                    ></vs-button>
                                                </vx-tooltip>
                                                <!-- if edit existing entry -->
                                                <vx-tooltip text="Speichern" position="left"
                                                            v-if="editContactOffset==indextr && editContact.id ">
                                                    <vs-button class="mr-1" color="success" type="filled" icon="send"
                                                               size="small"
                                                               @click="saveContactEditing()"
                                                    ></vs-button>
                                                </vx-tooltip>
                                                <vx-tooltip text="Abbrechen" position="left"
                                                            v-if="editContactOffset==indextr && editContact.id">
                                                    <vs-button class="mr-1" color="danger" type="filled"
                                                               icon="remove_circle_outline"
                                                               size="small"
                                                               @click="cancelContactEditing()"
                                                    ></vs-button>
                                                </vx-tooltip>
                                                <!-- if new entry -->
                                                <vx-tooltip text="Speichern" position="left"
                                                            v-if="editContactOffset==indextr && !editContact.id ">
                                                    <vs-button class="mr-1" color="success" type="filled" icon="send"
                                                               size="small"
                                                               @click="saveNewContact()"
                                                    ></vs-button>
                                                </vx-tooltip>
                                                <vx-tooltip text="Abbrechen" position="left"
                                                            v-if="editContactOffset==indextr && !editContact.id">
                                                    <vs-button class="mr-1" color="danger" type="filled"
                                                               icon="remove_circle_outline"
                                                               size="small"
                                                               @click="cancelContactEditing()"
                                                    ></vs-button>
                                                </vx-tooltip>
                                            </div>
                                        </vs-td>
                                    </vs-tr>
                                </template>
                            </vs-table>
                        </div>
                    </template>
                </vx-card>
            </div>

            <div class="vx-col lg:w-1/2 pt-6 b2b-panel" v-if="clientData.workspace_id == 2">
                <vx-card title="B2Bsellers Suite Informationen">

                    <vs-row>
                        <div class="vx-col lg:w-1/2 "
                             v-bind:class="[clientData.b2b_partner_contract_file == null || clientData.b2b_partner_commission == null ? '' : 'is-active']">

                            <strong>Ja, dieser Kunde ist ein B2Bsellers <u>Partner</u></strong> &nbsp;&nbsp;&nbsp;&nbsp;
                            <i>... oder ...</i><br>
                            <div class="flex mt-4">
                                <vs-button color="warning" type="border" class="mr-3 mb-auto" size="small"
                                           target="_blank"
                                           :href="`${$globalUrl}storage/client_files/${clientData.b2b_partner_contract_file}`"
                                           v-if="clientData.b2b_partner_contract_file">
                                    Aktuellen Partner-Vertag einsehen
                                </vs-button>
                                <label for="b2bPartnerContractUpload">
                                    <feather-icon icon="UploadIcon" class="inline-block mr-2"
                                                  svgClasses="w-8 h-8"/>
                                </label>
                                <input type="file" id="b2bPartnerContractUpload"
                                       @input="uploadDataProcessingB2BsellersPartnerContract"
                                       accept="application/pdf"><br>
                            </div>
                            <vs-button color="warning" type="border" class="mr-3 w-full mt-2 mb-auto" size="small"
                                       target="_blank"
                                       @click="deleteB2BsellersPartnerContractFile"
                                       v-if="clientData.b2b_partner_contract_file"
                            >
                                Entfernen
                            </vs-button>
                            <div class="mt-4">
                                <b class="mt-3">2. Wie viel % Provision erhält dieser Partner auf seine
                                    Lizenzverkäufe?</b><br>
                                <input type="number" class="vs-input--input normal" step="0.5"
                                       v-model.number="clientData.b2b_partner_commission"/>
                            </div>

                            <div class="mt-4">
                                <b class="mt-3">3. Partner-Level</b><br>
                                <v-select :options="partnerLevelOptions" class="w-full" label="label" v-model="clientData.b2b_partner_level"></v-select>
                            </div>

                        </div>

                        <div class="vx-col lg:w-1/2 pl-4 is-b2b-customer"
                             v-bind:class="[clientData.b2b_assigned_partner_id === null ? '' : 'is-active']">
                            <strong>Ja, dieser Kunde ist ein B2Bsellers <u>Kunde</u></strong><br><br>
                            Wer ist der betreuunde Partner zur Auszahlung der Provision?

                            <mg-select v-model="clientData.b2b_assigned_partner_id" :options="clients" name="clients"
                                       data-vv-as="Kunden"
                                       placeholder="Optional: Partneragentur auswählen" track-by="id" label="id"
                                       :custom-label="customClientLabel">
                                <template slot="singleLabel" slot-scope="{ option }">
                                    <strong>{{ option.company_name }}</strong>
                                </template>

                                <template slot="option" slot-scope="props">
                                          <span>
                                              {{ props.option.company_name }} <small>(Kd. Nr.: {{ props.option.customer_number }})</small></span>
                                </template>
                            </mg-select>

                        </div>
                    </vs-row>
                    <vs-row class="mt-3">
                        <div>
                            <h4>Zugwiesene Kunden</h4>
                            <vs-table :sst="true" @sort="handleSort" max-items="10" :data="allocatedClients"
                                      v-if="allocatedClients">
                                <template slot="thead">
                                    <vs-th sort-key="company_name">Firma</vs-th>
                                    <vs-th sort-key="customer_number">Kundennummer</vs-th>
                                    <vs-th sort-key="email">Email</vs-th>
                                    <vs-th sort-key="phone">Lizenz Owner</vs-th>
                                    <vs-th sort-key="phone">Aktion</vs-th>
                                </template>

                                <template slot-scope="{data}">
                                    <vs-tr :key="i" v-for="(client, i) in allocatedClients">
                                        <vs-td :data="client.company_name">
                                            {{ client.company_name }}
                                        </vs-td>
                                        <vs-td :data="client.customer_number">
                                            {{ client.customer_number }}
                                        </vs-td>
                                        <vs-td :data="client.email">
                                            {{ client.email }}
                                        </vs-td>
                                        <vs-td :data="client.isB2BsellersLicenseOwner">
                                            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
                                                <vx-tooltip text="Inaktiv">
                                                    <span class="mega--circle red"
                                                          v-if="!client.isB2BsellersLicenseOwner"></span>
                                                </vx-tooltip>
                                                <vx-tooltip text="Aktiver Kunde">
                                                    <span class="mega--circle green"
                                                          v-if="client.isB2BsellersLicenseOwner"></span>
                                                </vx-tooltip>
                                            </vs-col>
                                        </vs-td>
                                        <vs-td>
                                            <router-link :to="'/customers/'+client.id">
                                                <vx-tooltip text="Bearbeiten" position="left">
                                                    <vs-button color="warning" type="filled" icon="edit" size="small"
                                                               :to="'/customers/'+client.id"></vs-button>
                                                </vx-tooltip>
                                            </router-link>

                                        </vs-td>

                                    </vs-tr>
                                </template>

                            </vs-table>
                        </div>
                    </vs-row>
                </vx-card>
            </div>

            <div class="vx-col lg:w-1/2 pt-6">
                <vx-card title="Kunden-Informationen">
                    <div v-if="clientData.client_informations">
                        <div class="vx-row mt-4" v-for="clientInformation in clientData.client_informations">
                            <div class="vx-col lg:w-1/3">

                                <autocomplete :suggestions="clientInformationOpportunities" label="Label"
                                              :clickdata.sync="clientInformation.label"
                                              v-model="clientInformation.label"></autocomplete>

                            </div>
                            <div class="vx-col lg:w-2/3 width-90">
                                <vs-input class="w-full" label="Wert" data-vv-as="Wert"
                                          name="value" v-model="clientInformation.value"
                                          type="text"/>
                            </div>
                            <div class="deleteIcon" v-if="clientInformation.id">
                                <vx-tooltip text="Löschen" position="left">
                                    <vs-button color="primary"
                                               type="filled"
                                               icon="delete_forever"
                                               size="small"
                                               @click="openDeleteConfirm(clientInformation.id)"></vs-button>
                                </vx-tooltip>
                            </div>

                        </div>

                    </div>
                    <div class="vx-row mt-4">
                        <div class="vx-col flex lg:w-full justify-center mt-8">
                            <vs-button color="warning" type="border" class="mb-0 " @click="addEmptyClientInformation()">
                                Neue hinzufügen
                            </vs-button>
                        </div>
                    </div>
                </vx-card>
            </div>
            <div class="vx-col lg:w-1/2 pt-6">
                <vx-card title="Interner Kommentar" class="h-full">
                    <div class="vx-col lg:w-full">
                        <label for="comment" class="vs-input--label">nur für Interne Zwecke</label>
                        <vs-textarea class="w-full"
                                     data-vv-as="nur für Interne Zwecke"
                                     name="comment"
                                     id="comment"
                                     height="200px"
                                     v-model="clientData.internal_notice">
                        </vs-textarea>
                    </div>
                </vx-card>
            </div>
            <div class="vx-col lg:w-1/2 pt-6" v-if="!clientData.fast_registration">
                <vx-card title="Rechnungen">
                    <client-invoices :client-id="clientData.id" order-type="invoice">

                    </client-invoices>
                </vx-card>
            </div>
            <div class="vx-col lg:w-1/2 pt-6" v-if="!clientData.fast_registration">
                <vx-card title="Angebote" class="h-full">
                    <client-invoices :client-id="clientData.id" order-type="offer"></client-invoices>
                </vx-card>
            </div>
            <div class="vx-col lg:w-1/2 pt-6" v-if="!clientData.fast_registration">
                <vx-card title="Kontakt-Historie" class="h-full">

                </vx-card>
            </div>
            <div class="vx-col lg:w-1/2 pt-6" v-if="!clientData.fast_registration">
                <vx-card title="Verträge">
                    <client-contracts :client-id="clientData.id"></client-contracts>
                </vx-card>
            </div>
            <div class="vx-col lg:w-1/2 pt-6" v-if="!clientData.fast_registration">
                <vx-card title="Noch nicht in Rechnung gestellte Abrechnungspunkte" class="h-full">
                    <client-accountings :data-filter="emptyAccoutingsFilter"></client-accountings>
                </vx-card>
            </div>
            <div class="vx-col lg:w-1/2 pt-6">
                <vx-card title="Kundenpreise">
                    <client-custom-prices :client-id="clientData.id"></client-custom-prices>
                </vx-card>
            </div>
        </div>
        <div class="flex mt-8 justify-end">
            <vs-button color="warning" type="border" class="mb-4" :to="{name :'customers'}">Abbrechen</vs-button>
            <vs-button class="ml-3 mb-4" @click="saveClient">Speichern</vs-button>
        </div>
    </div>
</template>
<script>

import VueBootstrap4Table from '@/plugins/vue-bootstrap4-table-master/src/components/VueBootstrap4Table';
import ClientInvoices from "@/views/organization/clients/module/ClientInvoices";
import ClientAccountings from "@/views/organization/clients/module/ClientAccountings";
import ClientContracts from "@/views/organization/clients/module/ClientContracts";
import ClientCustomPrices from "@/views/organization/clients/module/ClientCustomPrices";
import UserToClient from "./module/UserToClient";
import MgSelect from "@/components/mg-select/MgSelect";
import autocomplete from "@/components/autocomplete/autocomplete";


import ApiService from "../../../api";
import moment from "moment";
import staticOptions from "@/mixins/static/options";
import axios from 'axios';
import * as qs from "qs";
import vSelect from 'vue-select'

export default {
    components: {
        'v-select': vSelect,
        ClientCustomPrices,
        VueBootstrap4Table,
        ClientInvoices,
        ClientAccountings,
        ClientContracts,
        UserToClient,
        MgSelect,
        autocomplete
    },
    data() {
        return {
            clientInformationOpportunities: [],
            data: [],
            clients: [],
            allocatedClients: [],
            fetched: false,
            clientData: null,
            contactData: [],
            countries: [],
            selectedForDialog: null,

            editContactOffset: -1,
            editContact: {},
            editContactOri: {},
            emptyContact: {
                "email": "",
                "salutation": "mr",
                "first_name": "",
                "last_name": "",
                "akad": null,
                "phone": "",
                "mobile": "",
                "letter_salutation": "Sehr geehrter Herr XXX",
                "internal_comment": "",
                "is_newsletter_recipient": 1,
                "contact2clients": {
                    "client_id": null,
                    "is_main_contact": 0,
                    "is_invoice_recipient": 0,
                    "active": 1,
                    "position": null,
                }
            },
            partnerLevelOptions: ['Pioneer', 'Specialist', 'Expert', 'Technology'],
        }
    },
    mounted() {
        this.fetchCountries();
        this.fetchClientData().then(() => {
            this.fetchAllocatedClients()
        });
        this.fetchContactData();
        this.fetchClientInformationOpportunities();
        this.fetchClients()

    },
    computed: {
        typeOptions() {
            return staticOptions.clientTypes;
        },
        salutationsOptions() {
            return staticOptions.salutations;
        },
        apiUrl() {
            return axios.defaults.baseURL;
        },
        emptyAccoutingsFilter() {
            return {
                order: {
                    'field': 'created_at',
                    'direction': 'DESC'
                },
                filter: [{
                    field: 'client_id',
                    value: this.clientData.id
                },
                    {
                        field: 'order_id',
                        expression: 'exact',
                        value: 'null'
                    }]

            }
        },
        ContactsFilter() {
            return {
                order: {
                    'field': 'created_at',
                    'direction': 'DESC'
                },
                /*filter: [{
                    field: 'client_id',
                    value: 1
                },
                {
                    field: 'order_id',
                    expression: '!=',
                    value: null
                }]*/

            }
        }
    },
    methods: {
        deleteDsgvoFile() {
            this.$vs.loading();

            let payload = {
                "dsgvo_file": ""
            };

            delete payload.customer_number;

            ApiService.put('clients/' + this.$route.params.id, payload)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.fetchClientData()
                        return this.$vs.notify({
                            title: 'Erfolgreich',
                            text: 'Die Datei wurde erfolgreich gelöscht',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'success'
                        })
                    }

                    this.$vs.notify({
                        title: 'Fehlgeschlagen',
                        text: 'Der Kunde konnte nicht bearbeitet werden.',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                })
                .catch((error) => {
                    this.$vs.notify({
                        title: 'Fehlgeschlagen',
                        text: 'Der Kunde konnte nicht bearbeitet werden.',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                })

        }, deleteB2BsellersPartnerContractFile() {
            this.$vs.loading();

            let payload = {
                "b2b_partner_contract_file": ""
            };

            delete payload.customer_number;

            ApiService.put('clients/' + this.$route.params.id, payload)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.fetchClientData()
                        return this.$vs.notify({
                            title: 'Erfolgreich',
                            text: 'Die Datei wurde erfolgreich gelöscht',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'success'
                        })
                    }

                    this.$vs.notify({
                        title: 'Fehlgeschlagen',
                        text: 'Der Kunde konnte nicht bearbeitet werden.',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                })
                .catch((error) => {
                    this.$vs.notify({
                        title: 'Fehlgeschlagen',
                        text: 'Der Kunde konnte nicht bearbeitet werden.',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                })

        },
        uploadDataProcessingContract(event) {
            this.$vs.loading();

            const files = event.target.files;
            const formData = new FormData();

            formData.append('file', files[0]);

            const url = `clients/${this.$route.params.id}/upload/data-processing-contract`;

            ApiService.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((response) => {
                document.getElementById("contractUpload").value = "";
                if (response.status === 200) {
                    this.fetchClientData();

                    return this.$vs.notify({
                        title: 'Erfolgreich',
                        text: 'Die Datei wurde erfolgreich hochgeladen.',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'success'
                    })
                } else {
                    this.$vs.notify({
                        title: 'Fehlgeschlagen',
                        text: 'Die Datei konnte nicht hochgeladen werden.',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })

                    this.$vs.loading.close();
                }
            }).catch(error => {
                document.getElementById("contractUpload").value = "";
                this.$vs.notify({
                    title: 'Fehlgeschlagen',
                    text: 'Die Datei konnte nicht hochgeladen werden.',
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                })

                this.$vs.loading.close();
            })
        },
        uploadDataProcessingNDAContract(event) {
            this.$vs.loading();

            const files = event.target.files;
            const formData = new FormData();

            formData.append('file', files[0]);

            const url = `clients/${this.$route.params.id}/upload/data-processing-nda-contract`;

            ApiService.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((response) => {
                document.getElementById("NDAcontractUpload").value = "";
                if (response.status === 200) {
                    this.fetchClientData();

                    return this.$vs.notify({
                        title: 'Erfolgreich',
                        text: 'Die Datei wurde erfolgreich hochgeladen.',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'success'
                    })
                } else {
                    this.$vs.notify({
                        title: 'Fehlgeschlagen',
                        text: 'Die Datei konnte nicht hochgeladen werden.',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })

                    this.$vs.loading.close();
                }
            }).catch(error => {
                document.getElementById("NDAcontractUpload").value = "";
                this.$vs.notify({
                    title: 'Fehlgeschlagen',
                    text: 'Die Datei konnte nicht hochgeladen werden.',
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                })

                this.$vs.loading.close();
            })
        },
        uploadDataProcessingB2BsellersPartnerContract(event) {
            this.$vs.loading();

            const files = event.target.files;
            const formData = new FormData();

            formData.append('file', files[0]);

            const url = `clients/${this.$route.params.id}/upload/data-processing-b2b-partner-contract`;

            ApiService.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((response) => {
                document.getElementById("b2bPartnerContractUpload").value = "";
                if (response.status === 200) {
                    this.fetchClientData();

                    return this.$vs.notify({
                        title: 'Erfolgreich',
                        text: 'Die Datei wurde erfolgreich hochgeladen.',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'success'
                    })
                } else {
                    this.$vs.notify({
                        title: 'Fehlgeschlagen',
                        text: 'Die Datei konnte nicht hochgeladen werden.',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })

                    this.$vs.loading.close();
                }
            }).catch(error => {
                document.getElementById("b2bPartnerContractUpload").value = "";
                this.$vs.notify({
                    title: 'Fehlgeschlagen',
                    text: 'Die Datei konnte nicht hochgeladen werden.',
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                })

                this.$vs.loading.close();
            })
        },

        fetchCountries() {
            this.countries = staticOptions.countries;
        },
        fetchClientInformationOpportunities() {
            ApiService.get('clients/client_information/index')
                .then((response) => {
                    if (response.data.status === 'success') {
                        for (var i = 0; i < response.data.result.length; i++) {
                            if (response.data.result[i].label != "") {
                                this.clientInformationOpportunities.push(response.data.result[i].label)
                            }
                        }
                        this.clientInformationOpportunities = Array.from(new Set(this.clientInformationOpportunities))
                    }
                })
        },
        fetchContactData() {
            ApiService.get('contacts/client/' + this.$route.params.id, {
                params: this.ContactsFilter,
                paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
            })
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.fetched = true;
                        this.contactData = response.data.result;
                    }
                })
        },

        formatDate(value) {
            return moment(new Date(value)).format('DD.MM.YYYY')
        },
        fetchClientData() {
            this.$vs.loading();

            return ApiService.get('clients/' + this.$route.params.id)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.$vs.loading.close();
                        this.fetched = true;
                        this.clientData = response.data.result;
                    } else {
                        this.$vs.loading.close();
                        this.$router.push({name: 'customers'});
                    }
                })
                .catch((error) => {
                    this.$vs.loading.close();
                    this.$router.push({name: 'customers'});
                })
        },

        saveClient() {
            let payload = this.clientData;

            delete payload.customer_number;

            ApiService.put('clients/' + this.$route.params.id, payload)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.fetchClientData()
                        return this.$vs.notify({
                            title: 'Erfolgreich',
                            text: 'Der Kunde wurde erfolgreich bearbeitet',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'success'
                        })
                    }

                    this.$vs.notify({
                        title: 'Fehlgeschlagen',
                        text: 'Der Kunde konnte nicht bearbeitet werden.',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                })
                .catch((error) => {
                    this.$vs.notify({
                        title: 'Fehlgeschlagen',
                        text: 'Der Kunde konnte nicht bearbeitet werden.',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                })
        },
        handleSort() {

        },
        addEmptyClientInformation() {
            this.clientData.client_informations.push({"client_id": this.clientData.id, "label": "", "value": ""})
        },
        selectType() {
        },
        openDeleteConfirm(id) {
            this.selectedForDialog = id;

            this.$vs.dialog({
                type: 'confirm',
                color: 'primary',
                title: 'Bestätigen',
                text: 'Sind Sie sich sicher dass diese Information gelöscht werden soll?',
                accept: this.acceptDelete,
                acceptText: 'Löschen',
                cancelText: 'Abbrechen'
            })
        },
        acceptDelete() {
            ApiService.delete('clients/client_information/' + this.selectedForDialog).then((response) => {

                this.fetchClientData();

                if (response.data.status === 'success') {
                    return this.$vs.notify({
                        title: 'Erfolgreich',
                        text: 'Die Information wurde erfolgreich gelöscht',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'success'
                    });
                }

                this.$vs.notify({
                    title: 'Fehlgeschlagen',
                    text: 'Die Information konnte nicht gelöscht werden.',
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                })
            }).catch((error) => {
                this.$vs.notify({
                    title: 'Fehlgeschlagen',
                    text: 'Die Information konnte nicht gelöscht werden.',
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                })
            })
        },
        startContactEditing(index) {
            this.editContactOffset = index
            this.editContact = this.contactData[index]
            this.editContactOri = JSON.parse(JSON.stringify(this.editContact))
        },
        saveContactEditing() {
            let payload = this.editContact;

            ApiService.put('contacts/' + this.editContact.id, payload)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.fetchContactData()
                        this.editContactOffset = -1
                        this.editContactOri = {}
                        this.editContact = {}
                        return this.$vs.notify({
                            title: 'Erfolgreich',
                            text: 'Der KOntakt wurde erfolgreich bearbeitet',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'success'
                        })
                    }

                    this.$vs.notify({
                        title: 'Fehlgeschlagen',
                        text: 'Der Kontakt konnte nicht bearbeitet werden.',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                })
                .catch((error) => {
                    this.$vs.notify({
                        title: 'Fehlgeschlagen',
                        text: 'Der Kontakt konnte nicht bearbeitet werden.',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                })


        },
        cancelContactEditing() {
            if (!this.editContact["id"]) {
                this.contactData.splice(this.editContactOffset, 1);
            } else {
                this.$set(this.contactData, this.editContactOffset, this.editContactOri)
            }
            this.editContactOffset = -1
            this.editContactOri = {}
            this.editContact = {}

        },
        createEmptyContact() {
            this.emptyContact.contact2clients.client_id = this.clientData.id
            this.contactData.push(JSON.parse(JSON.stringify(this.emptyContact)))
            this.startContactEditing(this.contactData.length - 1)
        },
        customClientLabel(option) {
            return `${option.customer_number} - ${option.company_name}`;
        },
        fetchAllocatedClients() {
            return ApiService.get(`clients?filter[0][field]=b2b_assigned_partner_id&filter[0][expression]=exact&filter[0][value]=` + this.clientData.id + `&limit=1000`)
                .then(response => {
                    if (response.status == 200) {
                        this.allocatedClients = response.data.result;
                    } else {
                        this.$vs.notify({
                            title: 'Fehler beim laden zugewiesener B2Bsellers Kunden',
                            text: '',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        })
                    }
                })
                .catch((error) => {
                    this.$vs.notify({
                        title: 'Fehler beim Kunden laden',
                        text: '',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                });
        },
        fetchClients() {
            return ApiService.get(`clients?workspace_id=2&limit=1000`)
                .then(response => {
                    if (response.status == 200) {
                        this.clients = response.data.result;
                    } else {
                        this.$vs.notify({
                            title: 'Fehler beim Kunden laden',
                            text: '',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        })
                    }
                })
                .catch((error) => {
                    this.$vs.notify({
                        title: 'Fehler beim Kunden laden',
                        text: '',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                });
        },
        saveNewContact() {
            let payload = this.editContact;

            ApiService.post('contacts', payload)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.fetchContactData()
                        this.editContactOffset = -1
                        this.editContactOri = {}
                        this.editContact = {}
                        return this.$vs.notify({
                            title: 'Erfolgreich',
                            text: 'Der Kontakt wurde erfolgreich erstellt',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'success'
                        })
                    }

                    this.$vs.notify({
                        title: 'Fehlgeschlagen',
                        text: 'Der Kontakt konnte nicht erstellt werden.',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                })
                .catch((error) => {
                    this.$vs.notify({
                        title: 'Fehlgeschlagen',
                        text: 'Der Kontakt konnte nicht erstellt werden.',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                })
        }
    },
}
</script>
<style lang="scss" scoped>
.internal-contacts .name,
.internal-contacts .function {
  font-size: .90rem;
  color: rgba(0, 0, 0, 0.4);
}

.created-on {
  color: rgba(0, 0, 0, 0.4);
}

.switch-label {
  padding-left: 10px;
}

.logo-upload input,
.nda-upload input,
.contract-upload input {
  display: none;
}


.deleteIcon {
  position: absolute;
  right: 0;
  margin-top: 26px;
  margin-right: 10px;
}

.width-90 > div {
  width: 95% !important;
}

tr.vs-table--tr.inline-edit .vs-component.vs-con-input-label.vs-input {
  width: auto;
}

.b2b-panel .vx-col.is-active {
  border: 2px solid #2ac516;
  padding: 12px;
}

</style>