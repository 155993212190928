<template>
  <div class="client-contracts" v-if="contractsData">
    <vs-table :sst="true" @sort="handleSort" max-items="10" pagination :data="contractsData">

      <template slot="thead" >
        <vs-th sort-key="type_id">Vertragstyp</vs-th>
        <vs-th sort-key="internal_comment">Kommentar</vs-th>
        <vs-th sort-key="contract_start">Vertragsbeginn</vs-th>
        <vs-th sort-key="contract_end">Vertragsende</vs-th>
        <vs-th sort-key="status">Status</vs-th>
        <vs-th>Actions</vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
          <vs-td :data="data[indextr].type.name">
            {{ data[indextr].type.name }}
          </vs-td>
          <vs-td :data="data[indextr].internal_comment">
            {{ data[indextr].internal_comment }}
          </vs-td>

          <vs-td :data="data[indextr].contract_start">
            {{ data[indextr].contract_start }}
          </vs-td>

          <vs-td :data="data[indextr].contract_end">
            {{ data[indextr].contract_end }}
          </vs-td>

          <vs-td v-if="data[indextr].status == '1'">
            <span class="mega--circle green"></span>
          </vs-td>
          <vs-td v-if="data[indextr].status == '2'">
            <span class="mega--circle yellow"></span>
          </vs-td>
          <vs-td v-if="data[indextr].status == '3'">
            <span class="mega--circle red"></span>
          </vs-td>

          <vs-td>
            <vx-tooltip text="Bearbeiten" position="left">
              <vs-button color="warning" type="filled" icon="edit" size="small"
              :to="'/contracts/' + data[indextr].id"
              ></vs-button>
            </vx-tooltip>
          </vs-td>

        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>

<script>
import ApiService from "../../../../api";

export default {
  name: 'ClientContracts',

  props: ['clientId'],

  data(){
    return {
      contractsData: null,
    }
  },
  mounted() {
    this.fetchContractsData()
  },
  methods: {
    fetchContractsData(){
      ApiService.get('contracts?filter[0][field]=client_id&filter[0][expression]=exact&filter[0][value]=' + this.clientId)
          .then((response) => {
            if (response.data.status === 'success') {
              this.fetched = true;
              this.contractsData = response.data.result;
            }
          })
    },
    handleSort(){

    }
  },
}
</script>