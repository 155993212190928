<template>
  <div class="client-invoices" v-if="invoicesData && ordersStatus">
    <vs-table  @sort="handleSort" max-items="10" pagination :data="invoicesData" stripe >
      <template slot="thead" >
        <vs-th sort-key="status">Status</vs-th>
        <vs-th sort-key="invoice_number">Rechnungs-Nr</vs-th>
        <vs-th sort-key="order_date">Datum</vs-th>
        <vs-th sort-key="sum">Betrag</vs-th>
        <vs-th>Actions</vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data" >

          <vs-td :data="data[indextr].label">
            <span v-if="data[indextr].order_status" v-html="data[indextr].order_status.label">{{ data[indextr].order_status.label }}</span>
            <span v-if="!data[indextr].order_status && data[indextr].order_type_id == 10"><span class="mg-label gray">Stornorechnung!</span></span>
          </vs-td>

          <vs-td :data="data[indextr].invoice_number">
            {{ data[indextr].invoice_number }}
          </vs-td>

          <vs-td :data="data[indextr].order_date">
            {{ formatDate(data[indextr].order_date) }}
          </vs-td>

          <vs-td :data="data[indextr].sum">
            {{ parseCurrency(data[indextr].sum_netto) }}
          </vs-td>

          <vs-td>
            <div class="column-actions-group flex">
              <vx-tooltip text="Einsehen" position="left">
                <vs-button class="mr-1" color="warning" type="filled"
                           icon="info_outline" size="small"
                           :to="'/invoices/view/'+ data[indextr].id"
                ></vs-button>
              </vx-tooltip>

              <vx-tooltip text="PDF Download" position="left">
                <vs-button class="mr-1" color="dark" type="border"
                           icon="get_app"
                           size="small"
                           :to="'/invoices/' + data[indextr].id"
                           ></vs-button>
              </vx-tooltip>

              <vx-tooltip text="Duplizieren & bearbeiten" position="left">
                <vs-button color="dark" type="border"
                           icon="content_copy" size="small"
                           :to="'/invoices/duplicate/'+ data[indextr].id"></vs-button>
              </vx-tooltip>
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <div v-if="yearlySum">
      <span v-for="(sum,statusIndex) in yearlySum">
        <span v-if="sum !== undefined">{{parseStatusNamebyId(statusIndex)}}: {{parseCurrency(sum)}}</span>
      </span>
    </div>

  </div>
</template>

<script>
import ApiService from "../../../../api";
import PriceHelper from "@/mixins/helper/price.helper";
import moment from 'moment';
import * as qs from "qs";
import QueryHelper from "../../../../mixins/helper/query.helper";

export default {
  name: 'ClientInvoices',

  props: ['clientId', 'orderType'],

  data(){
    return {
      invoicesData: null,
      ordersStatus: null,
      yearlySum: [],
      allOrdersStatus: [],

    }
  },
  computed: {
    RequestParams() {
      return {
        order: {
          'field': 'order_date',
          'direction': 'DESC'
        },
        filter: [{
          field: 'client_id',
          expression: 'exact',
          value: this.clientId
        },
          {
            field: 'type.invoice_type',
            expression: 'exact',
            value: this.orderType
          }]

      }
    }

  },
  mounted() {
    this.fetchInvoicesData()
    this.fetchOrderStatus()
    this.fetchAllOrderStatus()
  },
  methods: {
    fetchInvoicesData(){
      ApiService.get('orders', {
        params: this.RequestParams,
        paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
      })
          .then((response) => {
            if (response.data.status === 'success') {
              this.fetched = true;
              this.invoicesData = response.data.result;
              this.calcYearlySum();
            }
          })
    },
    fetchAllOrderStatus(){
      ApiService.get('order_status' )
              .then((response) => {
                if (response.data.status === 'success') {
                  this.allOrdersStatus = response.data.result;
                }
              })
    },
    fetchOrderStatus(){
      ApiService.get('order_status/' + 3)
          .then((response) => {
            if (response.data.status === 'success') {
              this.fetched = true;
              this.ordersStatus = response.data.result;
            }
          })
    },
    calcYearlySum(){
      if(this.fetched) {
        let yearlySum = [];
        for(let i = 0;i < this.invoicesData.length; i++) {
          if(this.invoicesData[i].type.invoice_type == this.orderType){
            yearlySum[this.invoicesData[i].order_status.id] = 0;
            yearlySum[this.invoicesData[i].order_status.id] = yearlySum[this.invoicesData[i].order_status.id] + this.invoicesData[i].sum_netto;
          }
        }
        this.yearlySum = yearlySum
      } else {
        this.yearlySum = null;
      }
    },
    handleSort(){

    },
    parseCurrency(value){
      return PriceHelper.formatCurrency(value)
    },
    formatDate(value) {
      return moment(new Date(value)).format('DD.MM.YYYY')
    },
    parseStatusNamebyId(statusId) {
      for (var i = 0; i < this.allOrdersStatus.length; i++) {
        if(this.allOrdersStatus[i].id === statusId) {
          return this.allOrdersStatus[i].name;
        }
      }
    }
  }
}
</script>